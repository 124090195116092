import api from './api';

const getGrades = async () => {
    try {
        const response = await api.get(`/grade`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getGrades
};