import api from './api';

const getEstudiantes = async () => {
    try {
        const response = await api.get(`/student`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createEstudiantes = async (studentData) => {
    try {
        const response = await api.post('/registration', studentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateEstudiantes = async (studentData) => {
    try {
        const response = await api.put('/registration', studentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};


const getEstudiante = async (identification) => {
    try {
        const response = await api.get(`/registration?id=${identification}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const subirImagenEstudiantes = async (image, studentId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('profilePicture', image);   
        formDataToSend.append('studentId', studentId);   
        const response = await api.post('/student/image-upload', formDataToSend,{
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getEstudiantes,
    createEstudiantes,
    subirImagenEstudiantes,
    getEstudiante,
    updateEstudiantes
};