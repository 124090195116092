import api from './api';

const getJourneys = async () => {
    try {
        const response = await api.get(`academic/journey`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getYears = async () => {
    try {
        const response = await api.get(`academic/schoolyear`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getYearById = async (yearId) => {
    try {
        const response = await api.get(`academic/schoolyear/${yearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createYear = async (yearData) => {
    try {
        const response = await api.post('academic/schoolyear', yearData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateYear = async (yearData) => {
    try {
        const response = await api.put('academic/schoolyear', yearData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteYearById = async (yearId) => {
    try {
        const response = await api.delete(`academic/schoolyear/${yearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export default {
    getJourneys,
    getYears,
    getYearById,
    createYear,
    updateYear,
    deleteYearById
};