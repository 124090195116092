import React, { useState } from 'react';
import Header from './components/header'
import Sidebar from './components/sidebar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routesArray from './routes';
import Login from './views/login';
import Inscriptions from './views/inscriptions';
import { ToastContainer } from 'react-toastify';
import '../src/App.css';



function App() {

  const mainRoutesArray = [
    <Route key="inscripciones" path="/" element={<Inscriptions />} />,
  ];
  
    return (
      <Router>
          <Routes>
            {mainRoutesArray}
          </Routes>
      </Router>
    );
}

export default App;
