import React, { useEffect, useState } from "react";
import "../css/StudentForm.css";
import StudentForm from "../components/Forms/StudentForm";

function Inscriptions() {
  const [selectedStudent, setSelectedStudent] = useState({
    registrationId: 0,
    profilePicture: null,
    aspirantId: "",
    currentYear: "2023",
    journeyId: "",
    grade: "",
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    identificationType: "",
    placeOfIssue: "",
    birthDate: "",
    gender: "",
    phone: "",
    cellPhone: "",
    address: "",
    disability: "",
    email: "",
    institution: "",
    city: "",
    department: "",
    fatherReferenceId: 0,
    fatherId: "",
    fatherPlaceOfIssue: "",
    fatherIdType: "",
    fatherAcademicLevel: "",
    fatherFirstName: "",
    fatherSecondName: "",
    fatherFirstSurname: "",
    fatherSecondSurname: "",
    fatherBirthDate: "",
    fatherCellphone: "",
    fatherPhone: "",
    fatherAddress: "",
    fatherEmail: "",
    fatherProfession: "",
    fatherWorkplace: "",
    fatherJobType: "",
    fatherPosition: "",
    fatherIsParentMain: true,
    motherReferenceId: 0,
    motherId: "",
    motherPlaceOfIssue: "",
    motherIdType: "",
    motherAcademicLevel: "",
    motherFirstName: "",
    motherSecondName: "",
    motherFirstSurname: "",
    motherSecondSurname: "",
    motherBirthDate: "",
    motherCellPhone: "",
    motherPhone: "",
    motherAddress: "",
    motherEmail: "",
    motherProfession: "",
    motherWorkplace: "",
    motherJobType: "",
    motherPosition: "",
    motherIsParentMain: true,
    studentLivingWith: "",
    enrollmentReason: "",
    recommendation: "",
  });

  return (
    <div className="container-fluid mb-1 inscription-page-inscripciones">
      <div className="row text-white-form  header-form-row">
        <div className="col-md-2 header-form-logo">
          <img
            src="https://colegiojerusalem.edu.co/wp-content/uploads/2021/09/Logo-Colegio-980x963.png"
            alt="Logo del Colegio"
            className="img-fluid"
          />
        </div>
        <div className="col-md-10">
          <h1 className="header-form-title">
            Bienvenidos al Colegio Jerusalem
          </h1>
          <p className="header-form-subtitle">Educamos para la eternidad.</p>
        </div>
      </div>

        <div className='card containerForm-inscripciones'>
            <StudentForm
                        student={0}
                        hideBtnInscription={false}
                        ReadOnly={false}
                        onClose={() => {
                            
                        }
                    }
                    />
        </div>
        
    </div>
  );
}

export default Inscriptions;
