import { event } from "jquery";
import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {

  const activeMenu = (event)=>{
      const sidebar = document.getElementById("sidebar");
      var menus = sidebar.getElementsByClassName("active");
      for (let index = 0; index < menus.length; index++) {
         const element = menus[index];
         element.className = element.className.replace("active","");
      }
      const item = event.target.tagName==="SPAN" || event.target.tagName==="I" ? event.target.parentNode : event.target;
      item.parentNode.className="sidebar-item active";
  }
  const showMenus = (event)=>{
    const item = event.target.tagName==="SPAN" || event.target.tagName==="I" ? event.target.parentNode : event.target;
    console.log(item.tagName);
    const closed = (item.className.includes("collapsed"));
    item.className = (closed) ? "sidebar-link": "sidebar-link collapsed";
    const subMenuList = item.parentNode.getElementsByClassName("sidebar-dropdown");
    if(subMenuList && subMenuList.length>0){
        for (let i = 0; i < subMenuList.length; i++) {
          subMenuList[i].getElementsByClassName("sidebar-dropdown");
          if (closed) {
            subMenuList[i].className="sidebar-dropdown list-unstyled collapse show";
          }
          else {
            subMenuList[i].className="sidebar-dropdown list-unstyled collapse";
          }
        }
        
      }
  }


  return (
    <nav id="sidebar" className="sidebar">

      <div className="sidebar-content js-simplebar">

        <Link to="/dashboard" className="sidebar-brand">
          <span className="align-middle me-3">
            <i className="fas fa-user-graduate"></i> GOBE SMART <br />
            <span className="light-text">Education</span>
          </span>
        </Link>

        <ul className="sidebar-nav">
          <li className="sidebar-header">Menú</li>
          <li className="sidebar-item active">
						<Link onClick={activeMenu}  className="sidebar-link" to="/dashboard">
              <i className="fas fa-tachometer-alt"></i>
              <span className="align-middle">Dashboard</span>
            </Link>
					</li>
          
          <li className="sidebar-item">
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed">
              <i className="fa-solid fa-graduation-cap"></i>
              <span className="align-middle">Académico</span>
            </a>
            <ul
              id="dashboards"
              data-bs-parent="#sidebar" className="sidebar-dropdown list-unstyled collapse"
            >
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-chalkboard-teacher"></i>
                  Docentes
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/estudiantes" className="sidebar-link">
                  <i className="fas fa-user-graduate"></i>
                  Estudiantes
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-book"></i>
                  Asignaturas
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-star"></i>
                  Notas
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-calendar-alt"></i>
                  Calendario Académico
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu} to="/ConfigureYears" className="sidebar-link">
                <i class="fa-regular fa-calendar-plus"></i>
                 Año Escolar
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu} to="/AcademicPeriod" className="sidebar-link">
                  <i class="fa-regular fa-calendar"></i>
                  Período Académico
                </Link>
              </li>
              {/* Agrega aquí más elementos del submenú si es necesario */}
            </ul>
          </li>
          <li className="sidebar-item">
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed" >
              <i className="fas fa-gavel"></i>
              <span className="align-middle">Disciplinario</span>
            </a>
            <ul id="disciplinario" data-bs-parent="#sidebar" className="sidebar-dropdown list-unstyled collapse">
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-eye"></i>
                  Observaciones
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-user-check"></i>
                  Asistencia
                </Link>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed">
              <i className="fas fa-dollar-sign"></i>
              <span className="align-middle">Pagos</span>
            </a>
            <ul id="pagos" data-bs-parent="#sidebar" className="sidebar-dropdown list-unstyled collapse">
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-history"></i>
                  Historial de Pagos
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-file-invoice-dollar"></i>
                  Estado de Cuentas
                </Link>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed">
              <i className="fas fa-chart-pie"></i>
              <span className="align-middle">Reportes</span>
            </a>
            <ul id="reportes" data-bs-parent="#sidebar" className="sidebar-dropdown list-unstyled collapse">
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-file-alt"></i>
                  Informes/Boletines
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-scroll"></i>
                  Actas
                </Link>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed">
              <i className="fas fa-cogs"></i>
              <span className="align-middle">Configuraciones</span>

            </a>
            <ul id="configuraciones" data-bs-parent="#sidebar" className="sidebar-dropdown list-unstyled collapse">
              <li className="sidebar-item">
                <Link onClick={activeMenu}  to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-sliders-h"></i>
                  Ajustes Generales
                </Link>
              </li>
              <li className="sidebar-item">
                <Link onClick={activeMenu} to="/mantenimiento" className="sidebar-link">
                  <i className="fas fa-shield-alt"></i>
                  Seguridad
                </Link>
              </li>
            </ul>
          </li>

          <li className="sidebar-item">
						<Link onClick={activeMenu}  className="sidebar-link" to="/mantenimiento">
              <i className="fas fa-tachometer-alt"></i>
              <span className="align-middle">inscripciones</span>
            </Link>
					</li>

          <li className="sidebar-item">              
            <a onClick={showMenus} data-bs-toggle="collapse" className="sidebar-link collapsed" >
              <i className="fa-solid fa-helmet-safety"></i>
              <span className="align-middle">User</span>
            </a>
            <ul
              id="pages"
              data-bs-parent="#sidebar" 
              className="sidebar-dropdown list-unstyled collapse"
            >
              <li className="sidebar-item" >
                <a className="sidebar-link" href="pages-profile.html">
                  Profile
                </a>
              </li>
              {/* Agrega aquí más elementos del submenú si es necesario */}
            </ul>
          </li>
          {/* Agrega más elementos de la lista según sea necesario */}
        </ul>

        <div className="sidebar-cta hide">
          <div className="sidebar-cta-content">
            <strong className="d-inline-block mb-2">
              Alerta de Notificaciones
            </strong>
            <div className="mb-3 text-sm">
              ¡Tienes nuevas notificaciones pendientes!
            </div>

            <div className="d-grid">
              <a
                href="https://themes.getbootstrap.com/product/appstack-responsive-admin-template/"
                className="btn btn-primary"
                target="_blank"
              >
                Ver Notificaciones
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
