import api from './api';

const getCollection = async (collectionName) => {
    try {
        const response = await api.get(`/collection?type=${collectionName}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getCollection
};