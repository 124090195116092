// api.js
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Asumiendo que el token se almacena en localStorage
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;